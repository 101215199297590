function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
    // hover mini-cart
    const fpalm = new function () {

        this.events = {
            change: new Event('change')
        }
        this.addToCart = (id, qty = '1', options = null) => {
            if (id) {
                let data = new FormData();
                data.append('id', id);
                data.append('qty', qty);
                data.append('action', 'cart_add');
                if (options) {
                    for (let [key, value] of Object.entries(options)) {
                        if (key.indexOf('option_') !== -1) {
                            data.append('p' + id + '_options[' + key.split('option_')[1] + ']', value);
                        }
                    }
                }

                this.send(data).then(resp => {
                    if (resp.success) {
                        this.updateMiniCart(resp.data);
                        let msg = resp.message ? resp.message : (resp.msg ? resp.msg : '');
                        if (msg) {
                            $.fancybox.open({
                                src: msg,
                                type: 'html'
                            })
                        }
                    }
                })
            }
        }
        this.updateResultPrice = (data) => {
            let resultEl = document.querySelector('.checkout-total');
            if (!resultEl) return;

            let bonusEl = resultEl.querySelector('.bonus');
            let promocodeEl = resultEl.querySelector('.promocode');


            let totalIndex = resultEl.children.length - 1;
            resultEl.children[0].children[1].innerHTML = data.cart_cost.toLocaleString() + ' &#8381;';
            resultEl.children[1].children[1].innerHTML = data.delivery_cost.toLocaleString() + ' &#8381;';
            resultEl.children[totalIndex].children[1].innerHTML = data.cost.toLocaleString() + ' &#8381;';
            if (data.bonus_cost > 0) {
                if (!bonusEl) {
                    bonusEl = document.createElement('div');
                    bonusEl.classList.add('bonus');
                    bonusEl.innerHTML = '<span>Оплата бонусами: </span><span></span>';
                    resultEl.insertBefore(bonusEl, resultEl.children[totalIndex]);
                }
                bonusEl.style.display = '';
                bonusEl.children[1].innerHTML = data.bonus_cost.toLocaleString() + ' &#8381;';
            } else {
                if(bonusEl) bonusEl.style.display = 'none';
            }
            if (data.promocode_cost > 0) {
                if (!promocodeEl) {
                    promocodeEl = document.createElement('div');
                    promocodeEl.classList.add('promocode');
                    promocodeEl.innerHTML = '<span>Скидка по промокоду: </span><span></span>';
                    resultEl.insertBefore(promocodeEl, resultEl.children[totalIndex]);
                }
                promocodeEl.style.display = '';
                promocodeEl.classList.add('promocode--success');
                promocodeEl.children[1].innerHTML = data.promocode_cost.toLocaleString() + ' &#8381;';
            } else {
                if(promocodeEl) {
                    promocodeEl.style.display = 'none';
                    promocodeEl.classList.remove('promocode--success');
                }
            }
            /*if (data.bonus_cost !== undefined) {
                let bonusEl;
                if (totalIndex === 3) {
                    bonusEl = resultEl.children[2];
                } else {
                    bonusEl = document.createElement('div');
                    bonusEl.classList.add('bonus');
                    bonusEl.innerHTML = '<span>Оплата бонусами: </span><span></span>';

                }
                if (data.bonus === 0) {
                    bonusEl.style.display = 'none';
                } else {
                    bonusEl.style.display = '';
                    bonusEl.children[1].innerHTML = data.bonus.toLocaleString() + ' &#8381;';
                }
            }*/
        }
        this.addOrder = (form) => {
            if (!this.validateOrderFields(form)) return;
            let data = new FormData(form);
            let parent = form.parentElement.parentElement;

            data.append('action', 'order_add');
            // получаем чекбоксы
            let checkboxes = form.querySelectorAll('[type="checkbox"]');

            checkboxes.forEach(el => {
                data.set(el.name, el.checked);
            });

            // снимаем скрытые поля;
            if (parseInt(data.get('checkout_delivery')) === 1) {
                data.set('checkout_delivery_date', '');
                data.set('checkout_delivery_address', '');
                data.set('checkout_delivery_time', '');
                data.set('checkout_receiver_other', 'false');
                data.set('checkout_delivery_anonym', 'false');
                data.set('checkout_receiver_contacts', '');
            }
            // убираем "доставить анонимно" если не отмечена галочка "получатель другой человек"
            parent.classList.add('processing');
            this.send(data).then(resp => {
                if (resp.success) {
                    if (resp.data.redirect) {
                        document.location.href = resp.data.redirect;
                    }
                } else if (resp.order && resp.order.success && resp.order.data.msorder) {
                    document.location.href = document.location.origin + document.location.pathname
                        + (document.location.search ? document.location.search + '&' : '?')
                        + 'msorder=' + resp.order.data.msorder;
                } else if (resp.validate.fields) {
                    let msg = resp.validate.msg || resp.validate.message;
                    this.setInvalidFields(resp.validate.fields, msg);
                    form.scrollIntoView();
                }
            }).finally(() => {
                parent.classList.remove('processing');
            });
        }
        this.updateCart = (key, qty, parent = null) => {
            if (key) {
                if (qty === 0) {
                    this.removeFromCart(key);
                } else {
                    let data = new FormData();
                    data.append('key', key);
                    data.append('qty', qty);
                    data.append('action', 'cart_update');
                    if (parent) {
                        parent.classList.add('processing');
                    }
                    this.send(data).then(resp => {
                        if (resp.success) {
                            this.updateMiniCart(resp.data);
                            if (resp.data.key) {
                                document.querySelector(`.cart__item[data-key="${resp.data.key}"] .total`).innerHTML = resp.data.cost.toLocaleString() + ' &#8381';
                                fpalm.updateResultPrice(resp.orderPrice.data);
                            }
                        }
                    }).finally(() => {
                        parent.classList.remove('processing');
                    })
                }

            }
        }
        this.removeFromCart = (key, parent) => {
            let data = new FormData();
            data.append('key', key);
            data.append('action', 'cart_remove');
            if (parent) {
                parent.classList.add('processing')
                this.send(data).then(resp => {
                    if (resp.success) {
                        fade('out', parent, 400, true);
                        this.updateMiniCart(resp.data);
                        this.updateResultPrice(resp.orderPrice.data);
                        this.updateDeliveryDate(resp.delivery_date);
                    } else {
                        let msg = resp.msg ? resp.msg : (resp.message ? resp.message : '');
                        if (msg) {
                            $.fancybox.open({
                                src: msg,
                                type: 'html'
                            })
                        }
                    }
                }).finally(() => {
                    if (parent) {
                        parent.classList.remove('processing');
                    }
                })
            }

        }
        this.cleanCart = (parent) => {
            let data = new FormData();
            data.append('action', 'cart_clean');
            if (parent) {
                parent.classList.add('processing');
            }
            this.send(data).then(resp => {
                if (resp.success) {
                    let msg = resp.msg ? resp.msg : (resp.message ? resp.message : '');
                    if (msg) {
                        $.fancybox.open({
                            type: 'html',
                            src: msg
                        });
                    }
                    this.updateMiniCart(resp.data);
                }
            }).finally(() => {
                parent.classList.remove('processing');
            })
        }
        this.updateDeliveryDate = (deliveryDate) => {
            if (this.datepicker) {
                this.datepicker.set('minDate', deliveryDate.date);
            }
            let warningEl = document.querySelector('.alert-warning.delivery-warning');
            if (!warningEl) {
                warningEl = document.createElement('div');
                warningEl.classList.add('alert', 'alert-warning', 'delivery-warning');
            }
            if (deliveryDate.msg) {
                warningEl.innerHTML = deliveryDate.msg;
                warningEl.style.display = 'block';
            } else {
                warningEl.innerHTML = '';
                warningEl.style.display = 'none';
            }
        }
        this.setEmptyCart = () => {
            let html = '<div class="alert alert-warning">Ваша корзина пуста<div>';
            let container = document.querySelector('.checkout__outer');
            if (container) {
                container.innerHTML = html;
            }


        }

        this.updateMiniCart = (data) => {
            let miniCart = document.querySelectorAll('.mini-cart-wrp');
            miniCart.forEach(el => {
                let sum = el.querySelector('.sum'),
                    counter = el.querySelector('.counter');
                if (parseInt(data.total_count) > 0) {
                    sum.innerHTML = data.total_cost.toLocaleString() + ' &#8381;';
                    counter.innerHTML = data.total_count;
                    sum.style.display = counter.style.display = 'block';
                } else {
                    sum.innerHTML = counter.innerHTML = '';
                    sum.style.display = counter.style.display = '';
                    this.setEmptyCart();
                }
            })
        }

        this.response = () => {

        }
        this.send = function (payload, json = true) {
            return fetch('/ajax', {
                method: 'POST',
                body: payload,
                headers: {
                    'X-Requested-With': 'FetchAPI'
                }
            }).then(resp => (json) ? resp.json() : resp.text()).catch(error => {
                $.fancybox.open({
                    src: 'Произошла ошибка',
                    type: 'html'
                })
            });
        }
        this.validateOrderFields = (form) => {
            let prefix = 'checkout_';
            let data = new FormData(form);
            let required = ['receiver', 'phone', 'email'];

            if (parseInt(data.get('checkout_delivery')) === 2) {
                required.concat(['delivery_date', 'delivery_address', 'delivery_time']);
                if (data.get('checkout_receiver_other') === 'on') {
                    required.push('receiver_contacts');
                }
            }


            console.log(required);

            let fields = [];

            let msg = 'Вы должны заполнить обязательные поля';
            required.forEach(field => {
                let validator = (field === 'email') ? 'email' : 'empty';
                if (!this.utils.validator[validator](data.get(prefix + field))) {
                    if (validator === 'email') {
                        msg += '<br/>Введен некорректный e-mail';
                    }
                    fields.push(field);
                }
            });
            console.log(fields);
            if (fields.length > 0) {
                this.setInvalidFields(fields, msg);
                form.scrollIntoView();
                return false;
            }
            return true;

        }
        this.setInvalidFields = (fields = [], msg = '') => {

            let prefix = 'checkout_';
            document.querySelectorAll('[name^="checkout_"]').forEach(el => {
                el.classList.remove('invalid');
            })
            fields.forEach(field => {
                console.log(prefix + field);
                document.querySelector('[name="' + prefix + field + '"]').classList.add('invalid');
            });
            $.fancybox.open({
                type: 'html',
                src: msg
            })
        }
        this.datepicker = false;
        this.productGallery = false;
        this.updateUserEventsCounter = (count) => {
            let counterEl = document.querySelector('.account-events-title');
            if (counterEl) {
                counterEl.innerHTML = 'Всего событий: ' + count;
            }
        }
        this.utils = {
            validator: {
                email: function (email) {
                    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
                },
                empty: function (value) {
                    return value.length > 0;
                }
            }
        }
    };
    // select plugin
    document.querySelectorAll('select').forEach(el => {
        NiceSelect.bind(el);
    });


    document.addEventListener('change', function (e) {
        if (e.target.name === 'product-modification') {
            let selectedOption = e.target.options[e.target.selectedIndex];
            let options = selectedOption.dataset;
            for (let [key, value] of Object.entries(options)) {
                e.target.setAttribute('data-' + key, value);
            }
            let productPriceEl = document.querySelector('#fpalm-product_' + e.target.dataset.pid + ' .price');
            let price = e.target.dataset.price,
                oldPrice = e.target.dataset.oldprice,
                img = e.target.dataset.img;
            if (productPriceEl) {
                console.log('ch');
                if (typeof productPriceEl.children[1] !== 'undefined') {
                    if (oldPrice != 0) {
                        productPriceEl.children[1].innerHTML = oldPrice + ' &#8381;';
                        productPriceEl.children[1].style.display = '';
                    } else {
                        productPriceEl.children[1].style.display = 'none';
                    }
                }
                productPriceEl.children[0].innerHTML = price + ' &#8381;';
            } else {
                let parent = document.querySelector('.product-list-item[data-pid="' + e.target.dataset.pid + '"]');
                if (parent) {
                    let priceEl = parent.children[2].children[1];
                    priceEl.children[1].innerHTML = oldPrice + ' &#8381;';
                    priceEl.children[0].innerHTML = price + ' &#8381;';
                }
            }
            if (img && fpalm.productGallery) {
                let slides = fpalm.productGallery.getInfo().slideItems;
                for (let slide of slides) {
                    if (slide.id && slide.dataset.imgId === img) {
                        let slideIndex = slide.id.match(/tns(\d*)-item(\d*)/)[2];
                        fpalm.productGallery.goTo(slideIndex);
                    }
                }
            }
        }
        if (e.target.type === 'radio' && e.target.name.indexOf('options') !== -1) {
            let productPriceEl = document.querySelector('#fpalm-product_' + e.target.dataset.pid + ' .price');
            console.log(productPriceEl);
            let price = e.target.dataset.price,
                oldPrice = e.target.dataset.oldprice,
                img = e.target.dataset.img;
            if (productPriceEl) {
                if (typeof productPriceEl.children[1] !== 'undefined') {
                    if (oldPrice != 0) {
                        productPriceEl.children[1].innerHTML = oldPrice + ' &#8381;';
                        productPriceEl.children[1].style.display = '';
                    } else {
                        productPriceEl.children[1].style.display = 'none';
                    }
                }
                productPriceEl.children[0].innerHTML = price + ' &#8381;';
            } else {
                let parent = document.querySelector('.product-list-item[data-pid="' + e.target.dataset.pid + '"]');
                if (parent) {
                    let priceEl = parent.children[2].children[1];
                    priceEl.children[1].innerHTML = oldPrice + ' &#8381;';
                    priceEl.children[0].innerHTML = price + ' &#8381;';
                }
            }
            if (img && fpalm.productGallery) {
                let slides = fpalm.productGallery.getInfo().slideItems;
                for (let slide of slides) {
                    if (slide.id && slide.dataset.imgId === img) {
                        let slideIndex = slide.id.match(/tns(\d*)-item(\d*)/)[2];
                        fpalm.productGallery.goTo(slideIndex);
                    }
                }
            }
        }

        if (e.target.name.indexOf('quantity') !== -1) {
            if (e.target.dataset.key) {
                fpalm.updateCart(e.target.dataset.key, parseInt(e.target.value), e.target.parentElement.parentElement);
            }
        }
        if (e.target.name.indexOf('checkout') !== -1) {
            let data = new FormData();
            data.append('action', 'order_update');
            if (e.target.type === 'checkbox') {
                data.append(e.target.name, e.target.checked);
            } else if (e.target.type === 'number') {
                let val = (e.target.max && parseInt(e.target.value) > parseInt(e.target.max)) ? e.target.max : e.target.value;
                data.append(e.target.name, val);
            } else {
                data.append(e.target.name, e.target.value);
            }

            fpalm.send(data).then(resp => {
                if (resp.updatePrice) {
                    fpalm.updateResultPrice(resp.orderPrice.data);
                    if (e.target.name === 'checkout_bonus' && parseInt(data.get('checkout_bonus')) !== resp.orderPrice.data.bonus) {
                        e.target.value = resp.orderPrice.data.bonus;
                    }
                    let fieldName = e.target.name.split('_')[1];
                    if(resp.fields[fieldName].data[fieldName]){
                        e.target.classList.add('bonuses--success');
                    } else {
                        e.target.classList.remove('bonuses--success');
                    }
                }
            })
        }
        if (e.target.name === 'checkout_delivery') {
            let fields = e.target.parentElement.nextElementSibling;
            if (+e.target.value === 1) {
                // скрываем доп поля;
                if (fields.style.display !== 'none') {
                    slide('up', fields);
                }
            } else {

                slide('down', fields);
                console.log('del 2');
            }
        }
        if (e.target.name === 'checkout_receiver_other') {
            let fields = e.target.parentElement.parentElement.parentElement.nextElementSibling;
            if (+e.target.checked) {
                // показываем доп поля;
                slide('down', fields);
            } else {
                // скрываем доп поля
                slide('up', fields);
                document.getElementById('checkout_delivery_anonym').checked = false;
            }
        }
        if (e.target.type === 'number') {
            if (e.target.max && parseInt(e.target.value) > parseInt(e.target.max)) {
                e.target.value = e.target.max;
            }
        }
    });
    document.addEventListener('click', function (e) {
        if (e.target.classList.contains('product-list-item__to-cart')) {

            e.preventDefault();
            let id = e.target.dataset.pid,
                qty = 1;
            let size = e.target.parentElement.querySelector('[name="p' + id + '_options[size]"]:checked');
            let optionsEl = e.target.parentElement.querySelector('select[name="product-modification"]');
            let options = null;
            if (optionsEl) {
                options = optionsEl.dataset;
            }
            fpalm.addToCart(id, qty, options);
        }


        if (e.target.classList.contains('btn--qty')) {
            changeProductQty(e.target);
        }
        if (e.target.id === 'button-cart') {
            let id = e.target.parentElement.querySelector('[name="product-id"]').value;
            let qty = e.target.parentElement.querySelector('[name="product-quantity"]').value;
            let size = document.querySelector('.fpalm-product input[name="options[size]"]:checked');
            let optionsEl = document.querySelector('.fpalm-product select[name="product-modification"]');
            let options = null;
            if (optionsEl) {
                options = optionsEl.dataset;
            }
            fpalm.addToCart(id, qty, options);
        }
        if (e.target.classList.contains('btn--submit')) {
            e.preventDefault();
            let form = e.target.parentElement.parentElement;
            if (form) {
                fpalm.addOrder(form);
            }
        }
        if (e.target.classList.contains('btn--remove')) {
            e.preventDefault();
            if (e.target.dataset.productKey) {
                fpalm.removeFromCart(e.target.dataset.productKey, e.target.parentElement.parentElement);
            }
        }
        if (e.target.classList.contains('account-tab')) {
            if (!e.target.classList.contains('account-tab--active')) {
                // удаляем активный класс и скрываем форму
                let active = document.querySelector('.account-tab--active');
                if (active) {
                    document.querySelector('.account-tab--active').classList.remove('account-tab--active');
                    document.querySelector('.account-form--active').classList.remove('account-form--active');
                }
                // Добавляем активный класс и показываем форму
                e.target.classList.add('account-tab--active');
                document.querySelector('.account-form[data-id="' + e.target.dataset.type + '"]').classList.add('account-form--active');

            }
        }
        if (e.target.classList.contains('open-account-modal')) {
            e.preventDefault();
            $.fancybox.open({src: '#account-popup', touch: false})
            document.querySelector('.account-tab').dispatchEvent(new Event('click'));
        }
        if (e.target.classList.contains('expand--sidebar')) {
            e.preventDefault();
            let btn = e.target;
            let state = btn.innerHTML === '+' ? 'close' : 'open';
            let submenu = btn.parentElement.nextElementSibling;
            if (submenu.classList.contains('sub')) {
                if (state === 'close') {
                    slide('down', submenu);
                } else {
                    slide('up', submenu);
                }
            }
            btn.innerHTML = state === 'open' ? '+' : '-';

        }
        if (e.target.classList.contains('cart--clean')) {
            e.preventDefault();
            fpalm.cleanCart(document.querySelector('.checkout__outer'));
        }
        if (e.target.classList.contains('account-orders-tab')) {
            console.log(e.target);
            if (!e.target.classList.contains('account-orders-tab--selected')) ;
            {
                let tab = e.target.dataset.tab
                document.querySelector('.account-orders-content--selected').classList.remove('account-orders-content--selected')
                document.querySelector('.account-orders-content[data-id="' + tab + '"]').classList.add('account-orders-content--selected');
                document.querySelector('.account-orders-tab--selected').classList.remove('account-orders-tab--selected');
                e.target.classList.add('account-orders-tab--selected');
            }
        }
        if (e.target.classList.contains('account-event-remove')) {
            let data = new FormData(),
                parent = e.target.parentElement.parentElement;
            data.append('action', 'remove_user_event');
            data.append('event_id', e.target.dataset.id);

            parent.classList.add('processing');
            fpalm.send(data)
                .then(resp => {
                    if (resp.success) {
                        fade('out', parent, 400, true);
                        if (resp.total_events !== undefined) {
                            fpalm.updateUserEventsCounter(resp.total_events);
                        }
                    } else {
                        let msg = resp.msg || resp.message;
                        $.fancybox.open({
                            type: 'html',
                            src: msg
                        });
                    }
                });


        }
    }, true);
    document.addEventListener('submit', function (e) {
        if (e.target.classList.contains('account-form')) {
            e.preventDefault();
            let data = new FormData(e.target),
                parent = e.target.parentElement.parentElement;

            data.append('action', 'account_' + e.target.dataset.id);
            parent.classList.add('processing');
            e.target.querySelectorAll('.invalid').forEach(el => el.classList.remove('invalid'));
            fpalm.send(data).then(resp => {
                if (!e.target.children[0].classList.contains('alert')) {
                    let err = document.createElement('div');
                    err.classList.add('alert');
                    e.target.insertBefore(err, e.target.children[0]);
                }
                let alert = e.target.children[0];
                let message = resp.msg ? resp.msg : (resp.message ? resp.message : '');
                if (message) {
                    alert.innerHTML = message;
                }
                if (resp.success) {
                    if (resp.redirect) {
                        document.location.href = resp.redirect;
                    } else {
                        alert.classList.remove('alert-danger');
                        alert.classList.add('alert-success');
                        if (e.target.dataset.id !== 'update') {
                            e.target.reset();
                        }
                        if (e.target.dataset.id === 'register') {
                            let fieldsToHide = e.target.querySelectorAll('.form-field');
                            fieldsToHide.forEach(el => el.style.display = 'none');

                            setTimeout(function () {
                                fieldsToHide.forEach(el => el.style.display = '');
                                alert.remove();
                                $.fancybox.close();

                            }, 3000);
                        }
                    }
                } else {
                    alert.classList.add('alert-danger');
                    alert.classList.remove('alert-success');
                    if (resp.errors) {
                        for (let [key, value] of Object.entries(resp.errors)) {
                            e.target.querySelector('[name="' + key + '"]').classList.add('invalid');
                        }
                    }
                }
            }).finally(() => {
                if (parent) {
                    parent.classList.remove('processing');
                }
            });
        }
        if (e.target.classList.contains('user-events-form')) {
            e.preventDefault();
            e.target.querySelectorAll('input').forEach(field => field.classList.remove('invalid'));
            e.target.classList.add('processing');
            fpalm.send(new FormData(e.target))
                .then(resp => {
                    if (resp.success) {
                        if (resp.event) {
                            let evt = document.createElement('div');
                            evt.classList.add('col-md-4');
                            evt.style.display = 'none';
                            let html = '<div class="account-event">';
                            html += '<div class="account-event-field account-event-name"><b>Имя: </b>' + resp.event.name + '</div>';
                            html += '<div class="account-event-field account-event-date"><b>Дата: </b>' + resp.event.date + '</div>';
                            html += '<div class="account-event-field account-event-event"><b>Событие: </b>' + resp.event.event + '</div>';
                            html += '<span class="account-event-remove" data-id="' + resp.event.event_id + '">';
                            html += '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>';
                            html += '</span>';
                            html += '</div>';

                            evt.innerHTML = html;

                            let parent = document.querySelector('.account-events-outer');
                            if (parent) {
                                parent.insertBefore(evt, parent.firstChild);
                                fade('in', evt, 400);
                            }
                            if (resp.total_events !== undefined) {
                                fpalm.updateUserEventsCounter(resp.total_events);
                            }


                        }
                    } else {
                        let msg = resp.msg || resp.message;
                        $.fancybox.open({
                            type: 'html',
                            src: msg
                        });

                        if (resp.validation) {
                            resp.validation.forEach(field => e.target.querySelector('[name="' + field + '"]').classList.add('invalid'));
                        } else {
                        }
                    }
                })
                .finally(() => {
                    e.target.classList.remove('processing');
                })
        }
    }, true);
    /*    let miniCart = document.querySelector('nav #mini-cart')
        if (miniCart) {
            miniCart.onmouseenter = () => {
                let miniCartList = miniCart.querySelector('.mini-cart-list .list')
                if (miniCartList.children.length > 0) {
                    miniCartList.parentElement.style.display = 'block'
                }
            }
            miniCart.onmouseleave = () => {
                miniCart.querySelector('.mini-cart-list').style.display = ''
            }
        }*/


    // mobile menu
    menuHandler()
    // project switcher and project slider on main page
    let mainSliders = initMainProjectsSlider()


    // mobile menu handler
    function menuHandler() {
        let header = document.querySelector('header'),
            navState = false,
            burger = document.querySelector('.burger'),
            menuWrapper = document.querySelector('.j-mobile-menu'),
            scroller = document.getElementById('mobileMenu-scroller')
        document.onclick = (e) => {
            if ((!e.target === header || !header.contains(e.target)) && e.target.id !== 'btnFilter') {
                closeMenu()
            }
        }
        const openMenu = () => {
            burger.classList.add('active')
            document.body.classList.add('mobile-hidden')
            menuWrapper.classList.add('active')
            navState = true;
        }
        const closeMenu = () => {
            burger.classList.remove('active')
            document.body.classList.remove('mobile-hidden')
            menuWrapper.classList.remove('active')
            navState = false;
        }
        burger.onclick = () => {
            navState ? closeMenu() : openMenu()
        }
        if (menuWrapper) {
            menuWrapper.onclick = (e) => {
                if (e.target.classList.contains('j-menu-item')) {
                    e.preventDefault()
                    let target = e.target.dataset.menu
                    let current = document.querySelector('.j-menu-top.active')
                    if (current) {
                        current.classList.remove('active')
                    }
                    document.querySelector('[data-id="' + target + '"]').classList.add('active')
                }
            }
        }
    }

    function initMainProjectsSlider() {
        let containers = {
            'banners': document.querySelector('.banners__gallery'),
            'insta': document.querySelector('.insta__gallery')
        }
        if (containers.banners) {
            let banners = tns({
                container: containers.banners,
                items: 1,
                controlsText: ['', ''],
                autoplay: true,
                autoplayButtonOutput: false,
                autoplayHoverPause: true
            })
        }
        if (containers.insta) {
            let insta = tns({
                container: containers.insta,
                items: 1,
                mouseDrag: true,
                controls: false,
                nav: true,
                controlsText: ['', ''],
                responsive: {
                    768: {
                        nav: false,
                        controls: true,
                        items: 5
                    }
                }
            })
        }


    }

    let productGalleryEl = document.querySelector('.product__gallery');
    if (productGalleryEl) {
        // product thumbs slider
        fpalm.productGallery = tns({
            container: ".product__gallery",
            navAsThumbnails: true,
            navContainer: ".product__gallery--thumb",
            controlsText: ['', ''],
            controls: false,
            responsive: {
                768: {
                    controls: true
                }
            }
        })
    }

    function customThrottle(func, wait, options) {
        var context, args, result;
        var timeout = null;
        var previous = 0;
        if (!options) options = {};
        var later = function () {
            previous = options.leading === false ? 0 : Date.now();
            timeout = null;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        };
        return function () {
            var now = Date.now();
            if (!previous && options.leading === false) previous = now;
            var remaining = wait - (now - previous);
            context = this;
            args = arguments;
            if (remaining <= 0 || remaining > wait) {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                previous = now;
                result = func.apply(context, args);
                if (!timeout) context = args = null;
            } else if (!timeout && options.trailing !== false) {
                timeout = setTimeout(later, remaining);
            }
            return result;
        };
    }

    liveSearchHandler()

    function liveSearchHandler() {
        let timer;
        let container = document.getElementById('search__result'),
            field = document.getElementById('live-search')
        if (container) {
            field.onkeyup = (e) => {
                if (field.value.length > 2) {
                    if (timer) clearTimeout(timer);
                    timer = setTimeout(() => {
                        liveSearch(field.value, container, field)
                    }, 250)
                } else {
                    container.style.display = '';
                }
            }
            field.onsearch = () => {
                container.style.display = '';

            }
        }

    }

    function liveSearch(str, container, field) {
        field.style.backgroundImage = "url('/assets/template/img/icons/loader.svg')";
        let data = new FormData();
        data.append('search', str);
        data.append('action', 'ajax_search');
        fpalm.send(data).then(resp => {
            if (resp.total) {
                let html = '';
                for (let [key, block] of Object.entries(resp.results)) {
                    html += '<div class="block"><div class="heading">' + block.title + ' (' + block.items.length + ')</div><ul class="list list-unstyled">';
                    for (let [k, item] of Object.entries(block.items)) {
                        html += '<li><a href="' + item.url + '">';
                        if (item.thumb !== undefined) {
                            html += '<span class="bg-cover img" style="background-image: url(' + item.thumb + ')"></span>';
                        }

                        html += '<span class="desc">';
                        html += '<span class="title">' + item.title + '</span>';
                        if (item.price !== undefined) {
                            html += '<span class="price">' + item.price + ' &#8381;</span>';
                        }
                        html += '</span>';
                        html += '</a></li>';
                    }
                    html += '</ul></div>';
                }
                if (html) {
                    container.innerHTML = html;
                    container.style.display = 'block';
                } else {
                    container.innerHTML = '<div class="block"><div class="heading">Ничего не найдено:( <br> Попробуйте изменить запрос</div></div>';
                    container.style.display = 'block';
                }
            } else {
                container.innerHTML = '<div class="block"><div class="heading">Ничего не найдено:( <br> Попробуйте изменить запрос</div></div>';
                container.style.display = 'block';
            }
        }).finally(() => {
            field.style.backgroundImage = '';
        });

        /*        fetch('index.php?route=common/ajax_search&q=' + str)
                    .then(resp => resp.json())
                    .then(data => {
                        if (Object.keys(data).length > 0) {
                            let html = ''
                            for (const [type, items] of Object.entries(data)) {
                                let heading = type === 'categories' ? 'Категории' : 'Товары'
                                html += '<div class="block"><div class="heading">' + heading + '</div><ul class="list list-unstyled">'
                                for (const [key, item] of Object.entries(items)) {
                                    html += '<li>'
                                    html += '<a href="' + item.url + '">'
                                    let img
                                    if (item.image) {
                                        img = item.image
                                    } else {
                                        img = '/catalog/view/theme/flowering_palm/html/img/product_2.jpg'
                                    }
                                    html += '<span class="img bg-cover" style="background-image: url(' + img + ')"></span>'
                                    html += '<span class="desc">'
                                    html += '<span class="title">' + item.name + '</span>'
                                    if (item.price !== undefined) {
                                        html += '<span class="price">' + item.price + '</span>'
                                    }
                                    html += '</span>'
                                }
                                html += '</ul></div>'
                                html += '</div>'
                            }
                            if ((data.products && data.products.length >= 6) || (data.categories && data.categories.length >= 6)) {
                                html += '<a href="#" class="more">Смотреть все результаты</a>'
                            }
                            if (html) {
                                container.innerHTML = html
                                container.style.display = 'block'
                            } else {
                                container.innerHTML = '<div class="block"><div class="heading">Ничего не найдено:( <br> Попробуйте изменить запрос</div></div>'
                                container.style.display = 'block'
                            }
                    } else {
                            container.innerHTML = '<div class="block"><div class="heading">Ничего не найдено:( <br> Попробуйте изменить запрос</div></div>'
                            container.style.display = 'block'
                        }
                    })
                    .finally(() => {
                        field.style.backgroundImage = ''
                    });*/
    }

    let deliveryDateEl = document.getElementById('checkout_delivery_date');
    if (deliveryDateEl) {
        fpalm.datepicker = flatpickr(deliveryDateEl, {
            dateFormat: 'd.m.Y',
            minDate: document.querySelector('.checkout-block.cart').dataset.mindate,
            locale: "ru"
        });
        console.log(fpalm);
    }

    let eventsDateEl = document.getElementById('user-events__date');
    if (eventsDateEl) {
        let eventsDatePicker = flatpickr(eventsDateEl, {
            dateFormat: 'd.m.Y',
            minDate: 'today',
            locale: 'ru',
        })
    }
    let userDobEl = document.getElementById('account_dob');
    if (userDobEl) {
        let userDobDatePicker = flatpickr(userDobEl, {
            dateFormat: 'd.m.Y',
            locale: 'ru',
        })
    }

    function switchFields(fields, readonly = true) {
        fields.forEach(el => {
            el.setAttribute('readonly', readonly);
        })
    }

    function changeProductQty(el, cart = false) {
        let action = el.dataset.action;
        let input = (action === 'plus') ? el.previousElementSibling : el.nextElementSibling;

        if (input) {
            let min = parseInt(input.min),
                value = parseInt(input.value);
            switch (action) {
                case 'plus':
                    value++;
                    break;
                case 'minus':
                    if (value > min) {
                        value--;
                    }
            }
            input.value = value;
            input.dispatchEvent(new Event('change', {bubbles: true}));
        }


    }

    function slide(direction = 'down', el, duration = 400) {
        // height
        if (el.offsetHeight === 0) {
            el.style.display = 'block';
        }
        let height = el.offsetHeight;
        let targetHeight, targetDisplay;
        el.style.overflow = 'hidden';
        el.style.transition = 'height ' + duration + 'ms';

        if (direction === 'down') {
            el.style.height = 0;
            targetHeight = height;
            targetDisplay = 'block';
        } else {
            el.style.height = height + 'px';
            targetHeight = 0;
            targetDisplay = 'none';
        }
        // show or hide;
        setTimeout(() => {
            el.style.height = targetHeight + 'px';
        }, 1);
        setTimeout(() => {
            el.style.display = targetDisplay;
            el.style.overflow = '';
            el.style.height = '';
            el.style.transition = '';
        }, duration)

    }

    function fade(dir, el, duration = 400, remove = false) {
        let targetOpacity, targetDisplay = '';
        if (dir === 'in') {
            el.style.opacity = 0;
            el.style.display = '';
            if (window.getComputedStyle(el).display === 'none') {
                el.style.display = 'block';
            }
            targetOpacity = 1;
        } else {
            el.style.opacity = 1;
            targetOpacity = 0;
            targetDisplay = 'none';
        }
        el.style.overflow = 'hidden';
        el.style.transition = 'opacity ' + duration + 'ms';

        // show or hide;
        setTimeout(() => {
            el.style.opacity = targetOpacity
        }, 1);
        setTimeout(() => {
            el.style.display = targetDisplay;
            el.style.overflow = '';
            el.style.transition = '';
            el.style.opacity = '';
            if (remove) {
                el.remove();
            }
        }, duration);
    }
})

$(function () {
    $(document).on('change', '#shipping_address_other_person_check_true', function () {
        if (this.checked) {
            $('.shipping_mkad_other_fields').slideDown()
            $('.simplecheckout-rule[data-for="shipping_address_other_person_contact"]').attr('data-required', true)
            $('.simplecheckout-rule[data-for="shipping_address_other_person_name"]').attr('data-required', true)
        } else {
            $('.shipping_mkad_other_fields').slideUp()
            $('.simplecheckout-rule[data-for="shipping_address_other_person_contact"]').removeAttr('data-required')
            $('.simplecheckout-rule[data-for="shipping_address_other_person_name"]').removeAttr('data-required')
        }
    })
})